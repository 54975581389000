.App {
  text-align: center;
}

.header {
  margin-top: 20px;
}

.content {
  margin-top: 20px;
}

table {
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}
